import React from 'react'
import Navbar from './navbar'
import Footer from './Footer'

const Simulation1 = () => {
    return (
        <>
            <Navbar />
            <section id="home-sec">
                <div class="overlay text-center">
                    <h1 > Managing Stakeholders - A Strategic Approach</h1><br />
                    <hr class="hr-set" />

                    <p>  Competition timing is from 9:00 am to 5:00 pm pm based on the local time zone
selected by participating teams.
</p>
                </div>
            </section>


            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" >
                            <div class="overlay text-center">
                                <h3><strong>Day 1 – International Project Management Team Excellence Award Competition. Participants gain 1 Year of experience in 1 Day</strong></h3>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 p-top-row " >
                            <div class="overlay text-center">
                                <img
                                    src="./images/prog1.jpg"
                                    alt="Program Day 1 IPMTEA"
                                    className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default Simulation1
