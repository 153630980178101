import React from 'react'
import Navbar from '../navbar';
import Footer from '../Footer';

const Programs = () => {
    return (
        <>
            <Navbar />
    <section id="home-sec-custom">
        <div class="overlay text-center">
        <h1 >Programs</h1>
            <h1 >Physical Mode:  16 - 17 November 2023</h1><br />
            {/* <hr class="hr-set"/> */}

            <h1 class="h1-custom-padding">Virtual Mode:  15 - 16 November 2023</h1>
        </div>
    </section>

    <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" >
                        <div class="overlay text-center">
                            <h1><strong>Competition timing is from 9:00 am to 5:00 pm Participants gain 4 Years of experience</strong></h1>
</div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 p-top-row " >
                        <div class="overlay text-center">
                            <img
                                src="./images/prog1.jpg"
                                alt="Program Day 1 IPMTEA"
                                className="img-fluid" />
                            <p> </p>
                            <img
                                src="./images/prog2.jpg"
                                alt="Program Day 2 IPMTEA"
                                className="img-fluid" />


                        <p> </p>
                            <img
                                src="./images/program-2023.jpg"
                                alt="Program Day 3 IPMTEA"
                                border="1"
                                className="img-fluid" />

                                </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}

export default Programs
