const winnerApi = [
    {
        id: 1,
        logo: "images/indianiolwinner.jpg",
        title: "WINNER TROPHY",
        info: "Northern Coalfields",
      },

      {
        id: 2,
        logo: "images/dvcrunner.jpg",
        title: "RUNNER-UP TROPHY",
        info: "PP&M Corporate Office (NTPC)",
      },

      {
        id: 3,
        logo: "images/ntpcsendrunner.jpg",
        title: "2 Runner-up Trophy & Best IOC Directorate Trophy",
        info: "IOC Refinery (Paradip)",
      },

];

export default winnerApi;