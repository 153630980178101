
import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const Navbar = () => {

  const [show, setShow] = useState(false);

  return (
    <>
      <section className="navbar-bg">

        <nav class="navbar navbar-expand-lg navbar-light ">
          <div class="container">
            <a class="navbar-brand text-center" href="#" >6<sup>th</sup> <span class="maroon">I</span>-PMTEA <br /><p> <h4>International - Project<br /> Management Team Excellence Award<br />Competition</h4></p>
            </a>


            <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation"
              onClick={() => setShow(!show)}>


              <span class="navbar-toggler-icon"></span>
            </button>
            <div class={`collapse navbar-collapse ${show ? "show" : ""}`} >
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <NavLink class="nav-link active" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>

                <li class="nav-item">
                  <NavLink class="nav-link" to="/about">
                    About
                  </NavLink>
                </li>


                <li class="nav-item">
                  <NavLink class="nav-link" to="/programs">
                  Schedule
                  </NavLink>
                </li>


                <li class="nav-item">
                  <NavLink class="nav-link" to="/pointtable">
                   Ranking
                  </NavLink>
                </li>

                <li class="nav-item">
                  <NavLink class="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </li>


              </ul>
              <form class="d-flex">

                <a href="https://register.ipmtea.com/" role="button" class="btn btn-style" type="submit">Register</a>
                <a href="https://www.cepm.com/download/I-PMTEA-2023.pdf" target="_blank" role="button" class="btn btn-style1" type="submit">Download Brochure</a>

              </form>
            </div>
          </div>
        </nav>
      </section>
    </>
  )
}

export default Navbar
