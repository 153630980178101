import React from 'react';
import './css/style.css';



const AboutComp = () => {
    return (
        <>
            <section id="home-sec">
                <div class="overlay text-center">
                    <h1 >About International - Project Management Team Excellence Award</h1><br />
                    <hr class="hr-set" />

                    <p>CEPM is organizing the Sixth International Project Management Team Excellence Award (I-PMTEA) </p>
                </div>

            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" >
                        <div class="overlay text-center">
                            <h3><strong>CEPM is organizing the Sixth International Project Management Team Excellence Award (I-PMTEA). This year, the 2023 International Project Management Team Excellence AWard(I-PMTEA 2023)
                            competition is being held in both Physical and Virtual Modes. Physical mode will be New Delhi for participating teams from India and Virtual mode for participating teams from outside India.</strong></h3>
</div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 p-top-row " >
                        <div class="overlay text-center">
                            <img
                                src="./images/about1.jpg"
                                alt="About IPMTEA"
                                className="img-fluid" />
                            <p> </p>
                            <img
                                src="./images/about2.jpg"
                                alt="About IPMTEA"
                                className="img-fluid" />
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutComp
